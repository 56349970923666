// Store for payment module and communication with onboarding store to and fro
import api from "../../assets/api.js";
import Vue from "vue";
import moment from 'moment';

const PAYMENT_SOURCE = {
  BILL_DAY_CHANGE: "BILL_DAY_CHANGE",
  TAKE_PAYMENT: "TAKE_PAYMENT",
  MOVE_IN: "MOVE_IN",
  SERVICES: "SERVICES",
  TRANSFERS: "TRANSFERS",
  INSURANCE: "INSURANCE",
  MOVE_OUT: "MOVE_OUT",
  AUCTION: "AUCTION",
  PROMOTION: "PROMOTION",
  BILLING_PROFILE: "BILLING_PROFILE",
  BILLING_CYCLE: "BILLING_CYCLE"
};

const PAYMENT_SOURCE_CONFIG_DEFAULT = {
  SHOW_AUTOPAY: true,
  SHOW_FUTURE_PAYMENTS: true,
  SHOW_PARTIAL_PAYMENTS: true,
  SHOW_SAVE_PAYMENT: true,
  SHOW_BILLING_ADDRESS: true,
  SHOW_TENANT_NAME: true,
  ALLOW_ADDITIONAL_PAYMENT: true,
  SHOW_INTER_PROPERTY_PAYMENT_SELECTOR: false
};

const PAYMENT_SOURCE_CONFIG = {
  BILL_DAY_CHANGE: {
    SHOW_AUTOPAY: true,
    SHOW_FUTURE_PAYMENTS: false,
    SHOW_PARTIAL_PAYMENTS: true,
    SHOW_SAVE_PAYMENT: true,
    SHOW_BILLING_ADDRESS: true,
    SHOW_TENANT_NAME: true,
    ALLOW_ADDITIONAL_PAYMENT: false,
    SHOW_INTER_PROPERTY_PAYMENT_SELECTOR: false
  },
  MOVE_OUT: {
    SHOW_AUTOPAY: false,
    SHOW_FUTURE_PAYMENTS: false,
    SHOW_PARTIAL_PAYMENTS: false,
    SHOW_SAVE_PAYMENT: true,
    SHOW_BILLING_ADDRESS: true,
    SHOW_TENANT_NAME: true,
    ALLOW_ADDITIONAL_PAYMENT: true,
    SHOW_INTER_PROPERTY_PAYMENT_SELECTOR: false
  },
  AUCTION: {
    SHOW_AUTOPAY: false,
    SHOW_FUTURE_PAYMENTS: false,
    SHOW_PARTIAL_PAYMENTS: false,
    SHOW_SAVE_PAYMENT: false,
    SHOW_BILLING_ADDRESS: false,
    SHOW_TENANT_NAME: false,
    ALLOW_ADDITIONAL_PAYMENT: true,
    SHOW_INTER_PROPERTY_PAYMENT_SELECTOR: false
  },
  SERVICES: {
    SHOW_AUTOPAY: true,
    SHOW_FUTURE_PAYMENTS: false,
    SHOW_PARTIAL_PAYMENTS: false,
    SHOW_SAVE_PAYMENT: false,
    SHOW_BILLING_ADDRESS: true,
    SHOW_TENANT_NAME: true,
    ALLOW_ADDITIONAL_PAYMENT: true,
    SHOW_INTER_PROPERTY_PAYMENT_SELECTOR: false
  },
  PROMOTION: {
    SHOW_AUTOPAY: true,
    SHOW_FUTURE_PAYMENTS: false,
    SHOW_PARTIAL_PAYMENTS: false,
    SHOW_SAVE_PAYMENT: true,
    SHOW_BILLING_ADDRESS: true,
    SHOW_TENANT_NAME: true,
    ALLOW_ADDITIONAL_PAYMENT: true,
    SHOW_INTER_PROPERTY_PAYMENT_SELECTOR: false
  },
  INSURANCE: {
    SHOW_AUTOPAY: true,
    SHOW_FUTURE_PAYMENTS: false,
    SHOW_PARTIAL_PAYMENTS: false,
    SHOW_SAVE_PAYMENT: true,
    SHOW_BILLING_ADDRESS: true,
    SHOW_TENANT_NAME: true,
    ALLOW_ADDITIONAL_PAYMENT: true,
    SHOW_INTER_PROPERTY_PAYMENT_SELECTOR: false
  },
  BILLING_PROFILE: {
    SHOW_AUTOPAY: true,
    SHOW_FUTURE_PAYMENTS: false,
    SHOW_PARTIAL_PAYMENTS: true,
    SHOW_SAVE_PAYMENT: true,
    SHOW_BILLING_ADDRESS: true,
    SHOW_TENANT_NAME: true,
    ALLOW_ADDITIONAL_PAYMENT: false,
    SHOW_INTER_PROPERTY_PAYMENT_SELECTOR: false
  },
  BILLING_CYCLE: {
    SHOW_AUTOPAY: true,
    SHOW_FUTURE_PAYMENTS: false,
    SHOW_PARTIAL_PAYMENTS: false,
    SHOW_SAVE_PAYMENT: true,
    SHOW_BILLING_ADDRESS: true,
    SHOW_TENANT_NAME: true,
    ALLOW_ADDITIONAL_PAYMENT: false,
    SHOW_INTER_PROPERTY_PAYMENT_SELECTOR: false
  },
};

function validatePaymentMethod({ payment, payment_method, payment_information, connections }) {
  let errors = [];
  let response = {
    status: true,
    msg: '',
  };

  switch(payment.type.toLowerCase()){
    case 'card':
      if(!payment_method.device_id){
        if(!payment_method.card_number) errors.push({ msg: "Card number is invalid" });
        if(!payment_method.exp_mo || !payment_method.exp_yr) errors.push({ msg: "Expiration date is invalid" });
        if(!payment_method.cvv2 ) errors.push({ msg: "CVV/CID is invalid" });
        if(payment_method.type==='card' && connections && connections.find(c => (c.name !== 'tenant_payments' && c.name !== 'fatzebra') && c.type === 'card') && !payment_method.address ) errors.push({ msg: "Address is invalid" });
        if(payment_method.type==='card' && connections && connections.find(c => (c.name !== 'fatzebra') && c.type === 'card') && !payment_method.zip ) errors.push({ msg: "Zip is invalid" });
      }
      break;
    case 'ach':
      if(!payment_method.account_number) errors.push({ msg: "Account number is invalid" });
      if(!payment_method.routing_number) errors.push({ msg: "Routing number is invalid" });
      if(!payment_method.account_type) errors.push({ msg: "ACH account type is invalid" });
      break;
    case 'check':
      if(!payment.number) errors.push({ msg: "Check number is invalid" });
      if(!payment.ref_name) errors.push({ msg: "Reference name is invalid" });
      break;
    case 'cash':
      if(!payment.ref_name) errors.push({ msg: "Reference name is invalid" });
      if(Number(payment_information.amountTendered) < Number(payment_information.totalNewPaymentPaying)) {
        errors.push({ msg: "Amount Tendered cannot be less then Total Amount" });
      } 
      break;
    case 'ivr':
      if(!payment_method.zip && connections && connections.find(c => ( c.name !== 'fatzebra')&& c.type === 'card')) errors.push({ msg: "Zip is invalid" });
      if(!payment_method.name_on_card) errors.push({ msg: "Name On Card is invalid" });
      if(payment_method.type==='card' && connections && connections.find(c => (c.name !== 'tenant_payments' && c.name !== 'fatzebra') && c.type === 'card') && !payment_method.address ) errors.push({ msg: "Address is invalid" });
      break;
    case 'savecard':
        if(!payment_method.zip && connections && connections.find(c => ( c.name !== 'fatzebra')&& c.type === 'card')) errors.push({ msg: "Zip is invalid" });
        if(payment_method.type==='card' && connections && connections.find(c => (c.name !== 'tenant_payments' && c.name !== 'fatzebra') && c.type === 'card') && !payment_method.address ) errors.push({ msg: "Address is invalid" });
        if(!payment_method.name_on_card) errors.push({ msg: "Name On Card is invalid" });
        break;
    default:
  }

  if(errors.length){
    response.status = false;
    response.errors = errors;
  }

  return response;
}

class Payments {
  constructor() {
    this.namespaced = true;

    this.state = {
      paymentSource: null,
      paymentSourceConfig: PAYMENT_SOURCE_CONFIG,
      contact: null,
      // leases => contains invoices which are even not selected by user
      leases: [],
      paymentMethods: [],
      contactOpenPayments: null,
      propertyConnections: null,
      disablePaymentButton: false,
      invoicesLoading: false,
      // Invoices sorted on basis of date and selected by user
      invoices: [],
      property: null,
      payment_information: {
        totalDue: null,
        totalNewPaymentPaying: 0,
        totalNewPaymentDue: 0,
        partial_payment: false,
        use_credits: false,
        totalCredits: 0,
        amountTendered: 0,
        // in case of transfers, transfer out balance
        transferCredits: 0
      },
      payment: {
        id: '',
        property_id: '',
        contact_id: '',
        payment_method_id: '',
        type: '',
        credit_type:'',
        number:'',
        ref_name: '',
        source: '',
        amount: '',
        date: '',
        transaction_id: '',
        amt_remaining: '',
        settle_date: ''
      },
      payment_method: {
        id: '',
        type: '',
        first: '',
        last: '',
        name_on_card: '',
        card_number: '',
        cvv2: '',
        exp_mo: '',
        exp_yr: '',
        save_to_account: 0,
        account_type: '',
        routing_number: '',
        account_number: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        device_id: '',
        connection_id: ''
      },
      checkErrors: null,
      reversal: {
        data: {},
        loading: false,
        error: null
      },
      refund: {
        data: {},
        loading: false,
        error: null
      },
      preSelectedLeaseId: null,
      settings: [],
      contact_company_data: {
        otp_id: '',
        contact_id: '',
        company_id: '',
        headers: '',
      },
      unitNumber: [],
      moveOutCalc: {},
      additionalCharges: []
    };

    this.getters = {
      getContactCompanyData:(state) => state.contact_company_data,
      getContact: (state) => state.contact,
      getLeases: (state) => state.leases,
      getDisablePaymentButton: (state) => state.disablePaymentButton,
      getContactPaymentMethods: (state) => state.paymentMethods,
      getContactOpenPayments: (state) => state.contactOpenPayments,
      getPropertyConnections: (state) => state.propertyConnections,
      getInvoicesLoading: (state) => state.invoicesLoading,
      getPaymentInformation: (state) => state.payment_information,
      getPayment: (state) => state.payment,
      getPaymentMethod: (state) => state.payment_method,
      getInvoices: (state) => state.invoices,
      getProperty: (state) => state.property,
      getPreSelectedLeaseId: (state) => state.preSelectedLeaseId,
      getLeaseUnitNumber: (state) => state.unitNumber,
      getPaymentSourceConfig: (state, getters, rootState, rootGetters) => {
        if(state.paymentSource && state.paymentSourceConfig[state.paymentSource]) {
          return state.paymentSourceConfig[state.paymentSource];
        } else {
          const DEFAULT_SOURCE_CONFIG = JSON.parse(JSON.stringify(PAYMENT_SOURCE_CONFIG_DEFAULT));
          if(state.paymentSource === PAYMENT_SOURCE.TAKE_PAYMENT && rootGetters['propertiesStore/isInterPropertyPayment'] && rootGetters['propertiesStore/filtered'].length > 1){
            DEFAULT_SOURCE_CONFIG.SHOW_AUTOPAY = false;
            //DEFAULT_SOURCE_CONFIG.SHOW_SAVE_PAYMENT = false;
            DEFAULT_SOURCE_CONFIG.SHOW_INTER_PROPERTY_PAYMENT_SELECTOR = true;
          }
          
          return DEFAULT_SOURCE_CONFIG;
        }
      },
      getSettings: (state) => {
        return state.settings;
      },
      getPaymentSource: (state, getters, rootState, rootGetters) => {
           return state.paymentSource;
      },
      autoPayLeases: (state) => {
        let autoPayLeases = [];
        state.leases && state.leases.map(l => {
          if(l.auto_charge) {
            autoPayLeases.push({ lease_id: l.id });
        }});

        return autoPayLeases;
      },
      getMinBilledMonths: (state, getters, rootState, rootGetters) => {
        if(state.paymentSource == 'MOVE_IN' || state.paymentSource == 'TRANSFERS') {
          return Math.min(rootGetters['onBoardingStore/billed_months'], rootGetters['onBoardingStore/min_billed_months']) || 0;
        }
        return 0;
      },
      shouldShowAdditionalMonths: (state, getters) => {
        if(state.paymentSource == 'MOVE_IN' && state.invoices && state.invoices.length && state.invoices[0].id) {
          return false;
        }
        return getters.getPaymentSourceConfig.SHOW_FUTURE_PAYMENTS;
      },
      getAutoPayRequiredForLease: (state, getters, rootState, rootGetters) => {
        return rootGetters['onBoardingStore/getAutoPayRequired'] || false;
      },
      getErrors: (state) => {
        return state.checkErrors;
      },
      formattedPaymentMethod: (state) => {
        switch(state.payment_method.type){
          case 'card':
            return {
              type: state.payment_method.type,
              name_on_card: state.payment_method.name_on_card,
              card_number: state.payment_method.card_number,
              exp_mo: state.payment_method.exp_mo,
              exp_yr: state.payment_method.exp_yr,
              cvv2: state.payment_method.cvv2,
              save_to_account: !!state.payment_method.save_to_account,
              auto_charge: !!state.payment_method.auto_charge,
              address: state.payment_method.address,
              address2: state.payment_method.address2,
              city: state.payment_method.city,
              state: state.payment_method.state,
              zip: state.payment_method.zip,
              country: state.payment_method.country,
            }
          case 'ach':
            return {
              type: state.payment_method.type,
              first: state.first,
              last: state.last,
              account_number: state.payment_method.account_number,
              routing_number: state.payment_method.routing_number,
              account_type: state.payment_method.account_type,
              save_to_account: !!state.payment_method.save_to_account,
              auto_charge: !!state.payment_method.auto_charge,
              address: state.payment_method.address,
              address2: state.payment_method.address2,
              city: state.payment_method.city,
              state: state.payment_method.state,
              zip: state.payment_method.zip,
              country: state.payment_method.country
            }
            case 'directdebit':
            return {
              type: state.payment_method.type,
              first: state.first,
              last: state.last,
              name_on_card: state.payment_method.name_on_card,
              account_number: state.payment_method.account_number,
              bsb_number: state.payment_method.bsb_number ? state.payment_method.bsb_number.slice(0, 3) + '-' + state.payment_method.bsb_number.slice(3) : "" ,
              save_to_account: !!state.payment_method.save_to_account,
              auto_charge: !!state.payment_method.auto_charge,
              
            }
            case 'Eftpos':
            return {
              transaction_id: state.payment_method.transaction_id,
              card_type: state.payment_method.card_type,
              type: state.payment_method.type,
            }
          case 'cash':
          case 'directdeposit':
          case 'check':
          case 'giftcard':
              break;
          default:
              break;   
        }
      },
      isAutoChargeSet: (state) => {
        return state.leases && state.leases.length && state.leases.filter(l => l.auto_charge).length ? true : false;
      },
      getReversal: (state) => state.reversal.data,
      getReversalLoading: (state) => state.reversal.loading,
      getRefundLoading: (state) => state.refund.loading,
      getReversalError: (state) => state.reversal.error,
      getSelectedLeases: (state) => {
        return state.leases?.length && state.leases.filter(l => l.pay); 
      },
      getLeaseIndex: (state) => (leaseId) => {
        if(!leaseId) return 0;
        return state.leases.findIndex(l => l.id === leaseId);
      },
      getLastBilled: (state) => (lease) => {
        if(lease.AdvanceInvoices?.length) {
          return moment(lease.AdvanceInvoices[lease.AdvanceInvoices.length - 1].period_end).format('YYYY-MM-DD');
        } else if(lease.OpenInvoices?.length) {
          return moment(lease.OpenInvoices[lease.OpenInvoices.length - 1].period_end).format('YYYY-MM-DD');
        } else {
          return null;
        }
      },
      getBilledMonths: (state) => (lease) => {
        let billedMonths = lease.AdvanceInvoices.length;
        if(state.paymentSource == 'MOVE_IN' || state.paymentSource == 'TRANSFERS') {
          billedMonths = billedMonths - 1;
        }
        return billedMonths;
      },
      getMoveOutCalc: (state) => state.moveOutCalc,
      getAdditionalCharges: (state) => state.additionalCharges,
      showP2PSelector: (state, getters, rootState, rootGetters) => ( getters.getPaymentSourceConfig.SHOW_INTER_PROPERTY_PAYMENT_SELECTOR && rootGetters['propertiesStore/isInterPropertyPayment'] && rootGetters['propertiesStore/filtered'].length > 1),
      shouldShowGiftCard: (state) => {
        const { settings = [] } = state;
        const isAuctionPayment = state.paymentSource === PAYMENT_SOURCE.AUCTION;
        if(isAuctionPayment) {
          return false;
        }

        return settings.some(s => (s.name === 'enableGiftCard' && s.value == '1'));
      }      
    };

    this.mutations = {

      setContactCompanyData(state, payload) {
        state.contact_company_data = payload;
      },

      checkOnlyCredit: (state, payload) => {
        if(
          state.payment_information.totalNewPaymentPaying == 0 &&
          state.payment_information.totalCredits > 0 && 
          state.payment_information.use_credits) {
          this.mutations.setCheckErrors(state, { value: false });
          // setCheckErrors({ value: true });
          } else {
            this.mutations.setCheckErrors(state, { value: true });
          }
      },

      setCheckErrors: (state, payload) => {
        state.checkErrors = payload.value;
      },
      setPaymentConfigProperty: (state, payload) => {
        const { property, propertyValue } = payload;
        Vue.set(state.paymentSourceConfig[state.paymentSource], property, propertyValue);
      },
      setPaymentSource: (state, payload) => {
        const { paymentSource } = payload;
        state.paymentSource = paymentSource;
      },

      setContact: (state, payload) => {
        const { contact } = payload;
        state.contact = contact;
      },

      setPreSelectedLeaseId: (state, payload) => {
        state.preSelectedLeaseId = payload;
      },

      setLeases: (state, payload) => {
        const { leases } = payload;
        state.leases = leases.length ? leases.map((l, index) => {
          return {
            AdvanceInvoices: l.AdvanceInvoices && l.AdvanceInvoices.length ? l.AdvanceInvoices : [],
            PaymentCycleOptions: l.PaymentCycleOptions || [],
            BillingCycleOptions: l.BillingCycleOptions || [],
            BillingCycle: l.BillingCycle,
            balance: l.balance,
            bill_day: l.bill_day,
            auto_charge: state.leases[index]?.auto_charge || !!l.auto_charge,
            billed_months: l.billed_months,
            current_property_date: l.current_property_date,
            deny_payments: l.deny_payments,
            discount_id: l.discount_id,
            enable_payment_cycles: l.enable_payment_cycles,
            end_date: l.end_date,
            has_open_invoice: l.has_open_invoice,
            id: l.id,
            lease_is_active: l.lease_is_active,
            lease_standing_id: l.lease_standing_id,
            monthly: l.monthly,
            open_balance: l.open_balance,
            payment_cycle: l.payment_cycle,
            security_deposit: l.security_deposit,
            start_date: l.start_date,
            statuses: l.statuses,
            status: l.status,
            to_overlock: l.to_overlock,
            pay: l.pay,
            payment_method_id: l.PaymentMethods && l.PaymentMethods[0] ? l.PaymentMethods[0].id : null,
            transferred_from: l.transferred_from,
            unit_id: l.unit_id,
            auction_status: l.auction_status,
            old_payment_cycle: l.payment_cycle || null,
            use_old_invoices: false,
            leaseInvoiceLoading: false,
            oldOpenInvoices: [],
            tax_exempt: l.tax_exempt,
            tax_exempt_description: l.tax_exempt_description,
            Unit: l.Unit? {
              Address: l.Unit.Address,
              address_id: l.Unit.address_id,
              category_id: l.Unit.category_id,
              id: l.Unit.id,
              number: l.Unit.number,
              product_id: l.Unit.product_id,
              property_id: l.Unit.property_id,
              type: l.Unit.type,
            } : {},
            Property: l.Property ? {
              LeaseTemplates: l.Property.LeaseTemplates,
              address_id: l.Property.address_id,
              company_id: l.Property.company_id,
              gds_id: l.Property.gds_id,
              is_day_closed: l.Property.is_day_closed,
              localCurrentDate: l.Property.localCurrentDate,
              name: l.Property.name,
              number: l.Property.number
            } : {},
            OpenInvoices: l.OpenInvoices && l.OpenInvoices.length ? l.OpenInvoices.map((i) => {
              return {
                InvoiceLines: i.InvoiceLines,
                balance: i.balance,
                amount: i.amount,
                credits_amount_used: i.credits_amount_used,
                contact_id: i.contact_id,
                created_by: i.created_by,
                date: i.date,
                discounts: i.discounts,
                due: i.due,
                id: i.id,
                lease_id: i.lease_id,
                number: i.number,
                open_payments: i.open_payments,
                period_end: i.period_end,
                period_start: i.period_start,
                property_id: i.property_id,
                reissued_from: i.reissued_from,
                rent_total: i.rent_total,
                status: i.status,
                sub_total: i.sub_total,
                total_discounts: i.total_discounts,
                total_due: i.total_due,
                total_payments: i.total_payments,
                total_tax: i.total_tax,
                type: i.type
              }
            }) : [],
          };
        }) : [];

        if (state.leases.length === 1) {
          if(state.paymentSource !== 'MOVE_OUT' && state.leases[0].auction_status === 'move_out') {
            Vue.set(state.leases[0], 'pay', false);
          } else {
            Vue.set(state.leases[0], 'pay', true);
          }
        }

        if(state.leases.length > 1){
          if(state.preSelectedLeaseId){
            let preSelectedIndex = state.leases.findIndex(lease => lease.id == state.preSelectedLeaseId);
            Vue.set(state.leases[preSelectedIndex], 'pay', true);
          }
          if(state.leases.filter(l => l.lease_is_active || l.lease_is_active == null).length === 1 && !state.preSelectedLeaseId){
            let onlyActiveLease = state.leases.findIndex(lease => lease.lease_is_active || lease.lease_is_active == null);
            Vue.set(state.leases[onlyActiveLease], 'pay', true);
          }
        }
      },

      setPaymentMethods: (state, payload) => {
        const { paymentMethods } = payload;
        state.paymentMethods = paymentMethods;
      },

      setLeaseProperty: (state, payload) => {
        const { leaseIndex, property, propertyValue } = payload;
        // eg: Vue.set(state.leases[leaseIndex], 'OpenInvoices', invoices);
        Vue.set(state.leases[leaseIndex], property, propertyValue);
      },
      setLeaseUnitNumber: (state, payload) => {
        if(payload == 'resetUnitNumber'){
          state.unitNumber = [];
        }else{
          const { leaseIndex, propertyValue } = payload;
          if(propertyValue === true){
            state.unitNumber.push(state.leases[leaseIndex].Unit.number);
          }else{
            let index = state.unitNumber.indexOf(state.leases[leaseIndex].Unit.number);
            state.unitNumber.splice(index, 1);
          }
        }
      },
      setContactOpenPayments: (state, payload) => {
        const { contactOpenPayments } = payload;
        state.contactOpenPayments = contactOpenPayments;
      },

      setPropertyConnections: (state, payload) => {
        const { propertyConnections } = payload;
        state.propertyConnections = propertyConnections;
      },

      createLeaseStructure: (state, payload) => {
        if(state.paymentSource != null) {
          const { openInvoices = [], invoices, lease, unit, updateInvoice, tax_exempt, tax_exempt_description } = payload;
          this.mutations.setLeases(state, { leases: [lease] });
          this.mutations.setLeaseProperty(state, { leaseIndex: 0, property: 'OpenInvoices', propertyValue: openInvoices });
          if(tax_exempt != null){
            this.mutations.setLeaseProperty(state, { leaseIndex: 0, property: 'tax_exempt', propertyValue: tax_exempt });
            this.mutations.setLeaseProperty(state, { leaseIndex: 0, property: 'tax_exempt_description', propertyValue: tax_exempt_description });
          }
          this.mutations.setLeaseProperty(state, { leaseIndex: 0, property: 'AdvanceInvoices', propertyValue: invoices });
          this.mutations.setLeaseProperty(state, { leaseIndex: 0, property: 'Unit', propertyValue: unit });

          if(state.paymentSource == 'TRANSFERS' || updateInvoice) {
            this.mutations.setInvoicesObject(state, { invoices: invoices });
          }
        }
      },

      updateInvoices: (state, payload) => {
        if(state.paymentSource != null) {
          const { invoices } = payload;
          this.mutations.setLeaseProperty(state, { leaseIndex: 0, property: 'OpenInvoices', propertyValue: invoices });
          this.mutations.setLeaseProperty(state, { leaseIndex: 0, property: 'AdvanceInvoices', propertyValue: [] });
          this.mutations.setInvoicesObject(state, { invoices: invoices });
        }
      },

      setLeaseBilledMonths: (state, payload) => {
        const { leaseIndex, billedMonths } = payload;
        if (billedMonths == 0){
          this.mutations.setLeaseProperty(state, { leaseIndex: leaseIndex, property: 'OpenInvoices', propertyValue: state.leases[leaseIndex].OpenInvoices });
        }
        this.mutations.setLeaseProperty(state, { leaseIndex: leaseIndex, property: 'billed_months', propertyValue: billedMonths });
      },

      resetLeaseBilledMonths: (state, payload) => {
        const { leases } = payload;
        this.mutations.setLeases(state, {leases});
      },
      
      setLeasePaymentCycle: (state, payload) => {
        const { leaseIndex, payment_cycle } = payload;
        this.mutations.setLeaseProperty(state, { leaseIndex: leaseIndex, property: 'payment_cycle', propertyValue: payment_cycle });
      },


      resetPayments: (state, payload) => {
        state.paymentSource = null;
        state.contact = null;
        state.leases = [];
        state.paymentMethods = [];
        state.contactOpenPayments = null;
        state.propertyConnections = null;
        state.preSelectedLeaseId = null;
        state.invoices = [];
        state.property = null;
        state.disablePaymentButton = false;
        state.invoicesLoading = false;
        state.payment_information = {
          totalDue: null,
          totalNewPaymentPaying: 0,
          totalNewPaymentDue: 0,
          partial_payment: false,
          use_credits: false,
          totalCredits: 0,
          amountTendered: 0,
          transferCredits: 0
        },
        state.payment = {
          id: '',
          property_id: '',
          contact_id: '',
          payment_method_id: '',
          type: '',
          credit_type:'',
          number:'',
          ref_name: '',
          source: '',
          amount: '',
          date: '',
          transaction_id: '',
          amt_remaining: ''
        },
        state.payment_method = {
          id: '',
          type: '',
          first: '',
          last: '',
          name_on_card: '',
          card_number: '',
          cvv2: '',
          exp_mo: '',
          exp_yr: '',
          transaction_id: '',
          card_type: '',
          save_to_account: false,
          account_type: '',
          routing_number: '',
          account_number: '',
          address: '',
          address2: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          device_id: '',
          connection_id: ''
        },
        state.checkErrors = null
      },

      setPaymentInformation: (state, payload) => {
        const { property, propertyValue } = payload;
        Vue.set(state.payment_information, property, propertyValue);
       
      },

      setPaymentInformationObject: (state, payload) => {
        const { paymentInformation } = payload;
        state.payment_information = { ...paymentInformation };
      },

      setPayment: (state, payload) => {
        const { property, propertyValue } = payload;
        Vue.set(state.payment, property, propertyValue);
      },

      setPaymentMethodID: (state, payload) => {
        if (payload == 'resetPaymentMethodID') {
          state.payment.payment_method_id = "";
        }
      },

      setPaymentObject: (state, payload) => {
        const { payment } = payload;
        state.payment = { ...payment };
      },

      setPaymentMethod: (state, payload) => {
        const { property, propertyValue } = payload;
        Vue.set(state.payment_method, property, propertyValue);
      },

      setPaymentMethodObject: (state, payload) => {
        const { paymentMethod } = payload;
        state.payment_method = { ...paymentMethod };
      },

      setProperty: (state, payload) => {
        const { property } = payload;
        state.property = { ...property };
      },

      setDisablePaymentButton: (state, payload) => {
        const { value } = payload;
        state.disablePaymentButton = value;
      },

      setInvoicesLoading: (state, payload) => {
        const { value } = payload;
        state.invoicesLoading = value;
      },

      pushInvoices: (state, payload) => {
        const { invoices } = payload;
        state.invoices.push(...invoices);
      },

      setInvoicesObject: (state, payload) => {
        const { invoices } = payload;
        state.invoices = invoices;
      },

      setReversal: (state, payload) => {
        const { property, propertyValue } = payload;
        Vue.set(state.reversal.data, property, propertyValue);
      },

      setReversalLoading: (state, payload) => {
        const { loading } = payload;
        Vue.set(state.reversal, 'loading', loading);
      },

      setRefundLoading: (state, payload) => {
        const { loading } = payload;
        Vue.set(state.refund, 'loading', loading);
      },

      setReversalError: (state, payload) => {
        const { error } = payload;
        Vue.set(state.reversal, 'error', error);
      },

      setReversalObject: (state, payload) => {
        const { reversal } = payload;
        state.reversal.data = reversal;
      },

      setSettings: (state, payload) => {
        const { settings } = payload;
        state.settings = settings;
      },
      setMoveOutCalc: (state, payload) => {
        const { moveOutCalc } = payload;
        state.moveOutCalc = moveOutCalc;
      },
      setAdditionalCharges: (state, payload) => {
        const { additionalCharges } = payload;
        state.additionalCharges = additionalCharges;
      },
      resetMoveOutCalc: (state) => {
        state.moveOutCalc = {};
      },
      resetAdditionalCharges: (state) => {
        state.additionalCharges = [];
      },
      resetReversal: (state) => {
        state.reversal.data = {};
      }
    };

    this.actions = {
     async checkSaveCardErrors({ commit, state, getters, dispatch },payload){
      payload.connections = state.propertyConnections
      let valid = validatePaymentMethod(payload);
      delete payload.connections
      if(!valid.status) {
        return {
          errors: valid.errors
        };
      }
     },
      async updateContactCompanyData({ commit }, data) {
        commit('setContactCompanyData', data);
      },
      
      async initPaymentStore({ commit, state, dispatch }, data) {
        const { paymentSource, invoice, propertyID } = data;
        commit("setPaymentSource", { paymentSource });
        commit("setProperty", { property: {id: propertyID} });
        await dispatch('fetchContactDetails', data);
        await dispatch('fetchContactPaymentMethods', data);
        await dispatch('fetchSettings', data);
      },

      async fetchContactLeasesWithInvoices({ commit, state, getters, dispatch }, data) {
        const { contactID, propertyID, leaseID, activeDate } = data;

        let properties = {};
        switch(state.paymentSource) {
          case PAYMENT_SOURCE.TAKE_PAYMENT:
            const r = await api.get(this.$app, api.CONTACTS + contactID + "/leases", {
              property_id: getters.showP2PSelector ? null: propertyID,
              active_date: activeDate,
            });

            commit("setLeases", { leases: r.leases });
            break;

          case PAYMENT_SOURCE.MOVE_IN:
            commit("onBoardingStore/setBilledMonths", getters.getMinBilledMonths, { root: true });
            await dispatch('onBoardingStore/getEstimate', {generateInvoices:true}, { root: true });
            commit("setLeaseProperty", { leaseIndex: 0, property: 'billed_months', propertyValue: getters.getMinBilledMonths });
            commit("setLeaseProperty", { leaseIndex: 0, property: 'auto_charge', propertyValue: getters.getAutoPayRequiredForLease });
            break;

          case PAYMENT_SOURCE.MOVE_OUT:
            const response = await api.get(this.$app, api.LEASES + leaseID, {source: 'move_out'});
            commit("setLeases", { leases: [response.lease] });
            break;
        }
      },

      async fetchContactDetails({ commit, state }, data) {
        const { contactID, setTransformedContact = false, guestMerchant = false } = data;
        if(contactID) {
          let r = await api.get(this.$app, api.CONTACTS + contactID);
          if (guestMerchant) {
            r.contact.first = 'Guest';
            r.contact.last = 'Checkout';
          }
          if(setTransformedContact){
            let transformedContact = {
              Addresses: r.contact.Addresses,
              Phones: r.contact.Phones,
              company_id: r.contact.company_id,
              email: r.contact.email,
              first: r.contact.first,
              id: r.contact.id,
              last: r.contact.last,
              middle: r.contact.middle,
              status: r.contact.status,
              driver_license: r.contact.driver_license,
              driver_license_city: r.contact.driver_license_city,
              driver_license_country: r.contact.driver_license_country,
              driver_license_exp: r.contact.driver_license_exp,
              driver_license_state: r.contact.driver_license_state,
              email_verified: r.contact.email_verified,
              ssn: r.contact.ssn,
              suffix : r.contact.suffix,
              rent_as_business: r.contact.rent_as_business,
              Representative: r.contact.Representative
            };
            commit("setContact", { contact: transformedContact });
          }else{
            commit("setContact", { contact: r.contact });
          }
          
        }
      },

      async fetchContactPaymentMethods({ commit, state }, data) {
        const { contactID, propertyID } = data;
        if(contactID) {
          const r = await api.get(this.$app, api.CONTACTS + contactID + "/payment-methods", {
            property_id: propertyID,
          });
          commit("setPaymentMethods", { paymentMethods: r.payment_methods });
        }
      },

      async fetchContactOpenPayments({ commit, state }, data) {
        const { contactID, propertyID } = data;
        if(contactID) {
          const r = await api.get(this.$app, api.CONTACTS + contactID + "/credits", {
            property_id: propertyID,
          });
          commit("setContactOpenPayments", { contactOpenPayments: r.payments });
        }
      },

      async getTransformedInvoices({ getters, commit, state, dispatch }, invoices){
        let transformedInvoices = invoices.map((i) => {
          return {
            InvoiceLines: i.InvoiceLines,
            company_id: i.company_id,
            balance: i.balance,
            amount: i.amount,
            credits_amount_used: i.credits_amount_used,
            contact_id: i.contact_id,
            created_by: i.created_by,
            date: i.date,
            discounts: i.discounts,
            due: i.due,
            id: i.id,
            lease_id: i.lease_id,
            number: i.number,
            open_payments: i.open_payments,
            paymentToApplyTotal: i.paymentToApplyTotal,
            period_end: i.period_end,
            period_start: i.period_start,
            property_id: i.property_id,
            rent_total: i.rent_total,
            status: i.status,
            sub_total: i.sub_total,
            total_amt: i.total_amt,
            total_discounts: i.total_discounts,
            total_due: i.total_due,
            total_payments: i.total_payments,
            total_tax: i.total_tax,
            type: i.type,
            utilities_total: i.utilities_total,
            tax_exempt: i.Lease.tax_exempt,
            tax_exempt_description: i.Lease.tax_exempt_description
          }
        })
        return transformedInvoices;
      },

      async fetchAdvanceLeasePayments({ getters, commit, state, dispatch }, data) {
        const {
          lease: { id: leaseID, billed_months, payment_cycle, auto_charge},
          leaseIndex,
        } = data;
            
        switch(state.paymentSource) {
          case PAYMENT_SOURCE.TAKE_PAYMENT:
            const r = await api.get(this.$app, api.LEASES + leaseID + "/advance-pay", {
              billed_months: Math.max(billed_months, getters.getMinBilledMonths),
              payment_cycle: payment_cycle || '',
            });

            commit("setLeaseProperty", {
              leaseIndex,
              propertyValue: await dispatch('getTransformedInvoices', r.invoices),
              property: "AdvanceInvoices",
            });

            commit("setLeaseProperty", {
              leaseIndex,
              propertyValue: await dispatch('getTransformedInvoices', openInvoices),
              property: "OpenInvoices",
            });

            break;

          case PAYMENT_SOURCE.MOVE_IN:
            commit("onBoardingStore/setBilledMonths", Math.max(billed_months + 1, getters.getMinBilledMonths), { root: true });
            commit("onBoardingStore/setLeaseProperty", {property: 'billed_months', propertyValue: Math.max(billed_months, getters.getMinBilledMonths)}, { root: true });
            commit("onBoardingStore/setLeaseProperty", {property: 'payment_cycle', propertyValue: payment_cycle}, { root: true });
            commit("onBoardingStore/setLeaseProperty", {property: 'auto_charge', propertyValue: auto_charge}, { root: true });
            await dispatch('onBoardingStore/getEstimate', {generateInvoices: true}, { root:true });
            break;

          case PAYMENT_SOURCE.TRANSFERS:
            commit("onBoardingStore/setBilledMonths", Math.max(billed_months + 1, getters.getMinBilledMonths), { root: true });
            commit("onBoardingStore/setConfigurationLeaseProperty", {property: 'billed_months', propertyValue: Math.max(billed_months, getters.getMinBilledMonths)}, { root: true });
            commit("onBoardingStore/setConfigurationLeaseProperty", {property: 'payment_cycle', propertyValue: payment_cycle}, { root: true });
            commit("onBoardingStore/setConfigurationLeaseProperty", {property: 'auto_charge', propertyValue: auto_charge}, { root: true });
            await dispatch('onBoardingStore/getEstimate', {config:true, is_transfer:1, transfer_lease_id: data.transfer_lease_id }, { root:true });
            break;
        }
      },

      async getNonDueOpenInvoices({state, getter},{invoices, openInvoices}){
        console.log("Invoices",invoices);
        console.log("OpenInvoices", openInvoices);
        const newOpenInvoices = []; // Create a new array for open invoices
        newOpenInvoices.push(...invoices.filter(invoice => invoice.id && invoice.number)); // Filter and push valid invoices
        openInvoices.push(...newOpenInvoices); // Add all valid invoices to openInvoices
        invoices = invoices.filter(invoice => !(invoice.id && invoice.number)); // Filter out the copied invoices
      return { invoices, openInvoices };
      },

      async fetchBilledMonthsByAmount({ state, getters, rootGetters }, data) {        
        const leases = getters.getSelectedLeases;
        const payment = getters.getPaymentInformation;
        const amount = payment.totalNewPaymentPaying - payment.totalNewPaymentDue;
        
        let reqData = {
          amount: Math.round((amount) * 1e2) / 1e2 
        }; 

        // ROUNDOFF          
        if(state.paymentSource == 'TRANSFERS') {
          const configureData = rootGetters['onBoardingStore/configureObject']({ is_transfer: true });
          reqData.is_lease_created = false;
          reqData.leases = [{
            ...configureData,
            last_billed: getters.getLastBilled({ ...leases[0] }),
            billed_months: getters.getBilledMonths(leases[0]), 
            unit_id: leases[0].Unit?.id,
            payment_cycle: leases[0].payment_cycle || null,
            auto_charge: leases[0].auto_charge || false
          }];
        } else {
          let leasesData = leases.map(l => ({
            id: l?.id || null,
            billed_months: getters.getBilledMonths(l), 
            last_billed: getters.getLastBilled({ ...l }),
            payment_cycle: l.payment_cycle || null,
            auto_charge: l.auto_charge || false
          }));

          reqData.leases = leasesData;
        }

        const res = await api.post(this, `${api.PAYMENTS}/get-leases-billed-months`, { ...reqData });
        return res;
      },

      async fetchPropertyConnections({ commit, state }, data) {
        const { propertyID } = data;
        commit("setPropertyConnections", {
          propertyConnections: [],
        });
        let r = await api.get(this.$app, api.PROPERTIES + propertyID + "/connections");
        commit("setPropertyConnections", {
          propertyConnections: r.connections,
        });
      },

      async fetchSettings({ commit }, data) {
        const { propertyID } = data;

        const r = await api.get(this, api.SETTINGS, { 
          category: 'transactional',
          property_id: propertyID
        });

        commit("setSettings", { settings: r.settings });
      },

      async reversePayment({ commit, state }, data) {
        const { paymentId, isRefund, void_data, credit_data, is_credit_invoice_line} = data;
        const reqData = data.reversal || state.reversal;
        if(isRefund) {
          commit("setRefundLoading", { loading: true });
        } else {
          commit("setReversalLoading", { loading: true });
        }
        commit("setReversalError", { error: null });
        if(reqData.data.reversal_type === 'directrefund' && is_credit_invoice_line === true){
          console.log("Inside is_credit_invoice_line in reverse PAYMENT");
          
  
          try {          
            reqData.data.credit_data = credit_data;
            const paymentDetails = {
              ...(reqData.data.credit_data.PaymentDetails[0] || []),
                account_name: reqData.data.account_name,
                account_number: reqData.data.account_number,
                bsb_number: reqData.data.bsb_number,
            };
            reqData.data.credit_data.PaymentDetails = [paymentDetails];
            const payload_for_credit = {
                PaymentDetails: reqData.data.credit_data.PaymentDetails,
                amount: reqData.data.credit_data.credit_amount,
            };

            console.log("payload_for_credit", payload_for_credit);
            
            const response = await api.post(this, api.INVOICES + `${reqData.data.credit_data.invoice_id}/invoice_line/${reqData.data.credit_data.line_id}/credit`,  payload_for_credit)
            commit("setReversalError", { error: '' });
          } catch (err) {
            commit("setReversalError", { error: err });
          } finally {
            commit("setReversalLoading", { loading: false });
            commit("setRefundLoading", { loading: false });
          }
        }
        else if(reqData.data.reversal_type === 'directrefund'){
          try {          
            reqData.data.void_data = void_data; 
            const response = await api.post(this, `${api.PAYMENTS}${paymentId}/direct_refund`, reqData.data);
            commit("setReversalError", { error: '' });
          } catch (err) {
            commit("setReversalError", { error: err });
          } finally {
            commit("setReversalLoading", { loading: false });
            commit("setRefundLoading", { loading: false });
          }

        }else{
          try {
            delete reqData.data?.account_number;
            delete reqData.data?.bsb_number;
            delete reqData.data?.payment_method;
            delete reqData.data?.account_name;
            
            const response = await api.post(this, `${api.PAYMENTS}${paymentId}/refund`, reqData.data);
            commit("setReversalError", { error: '' });
          } catch (err) {
            commit("setReversalError", { error: err });
          } finally {
            commit("setReversalLoading", { loading: false });
            commit("setRefundLoading", { loading: false });
          }
        }

        
      },

      async removeExtraAdvanceInvoices({ commit, state }, data) {
        const { leaseIndex, factor } = data;
        const newAdvanceInvoices = state.leases[leaseIndex].AdvanceInvoices.slice(0, -factor);
        const removedAdvanceInvoices = state.leases[leaseIndex].AdvanceInvoices.filter(obj1 => !newAdvanceInvoices.some(obj2 => obj1.lease_id === obj2.lease_id && obj1.period_start === obj2.period_start && obj1.period_end === obj2.period_end ));
        
        commit("setLeaseProperty", {
          leaseIndex,
          propertyValue: newAdvanceInvoices,
          property: "AdvanceInvoices",
        });

        return removedAdvanceInvoices;
      },

      async getTransformedPaymentObject({ getters, commit, state }, data) {
        const { formErrors } = data;
       
        if(formErrors) {
          return {
            errors: [{ msg: "You have errors on your form. Please correct them before continuing." }]
          };
        }
        
        if(!state.payment_information.partial_payment) {
          // this.setAmountDue(false);    // need this ?

          if(state.payment.id && state.payment.amt_remaining < state.payment_information.totalNewPaymentPaying) {
            return {
              errors: [{ msg: `There is only "${state.payment.amt_remaining}" remaining on this ${state.payment.credit_type}` }]
            };
          }
        }
        
        if(!(data.type=='saveCard') && !state.payment_information.use_credits && state.payment_information.transferCredits <= 0 && state.payment_information.totalNewPaymentPaying < .01 && state.paymentSource != "TRANSFERS") {
          return {
            errors: [{ msg: `Payment amount must be $.01 or more` }]
          };
        }
        
        if(state.payment_information.totalNewPaymentPaying > (Math.round(state.payment_information.totalNewPaymentDue * 1e2)/1e2)) {
          return {
            errors: [{ msg: `Payment amount must be ${Math.round(state.payment_information.totalNewPaymentDue * 1e2)/1e2} or less` }]
          };
        }
        
        let payment_method = null;
        let payment = {};
        
        if(state.payment_information.totalNewPaymentPaying > 0) {
          payment_method = !state.payment_method.device_id ? getters.formattedPaymentMethod : state.payment_method;
          payment = { ...state.payment };
          payment.amount = state.payment_information.totalNewPaymentPaying;
          payment.contact_id = state.contact && state.contact.id ? state.contact.id : null;
          payment.property_id = state.property.id;
          payment.amount_tendered = payment.type && payment.type.toLowerCase() == 'cash' ? Number(state.payment_information.amountTendered) : null;
         if(data.type){
          payment.type  = data.type
         }
          if((!payment.payment_method_id || !payment.payment_method_id.length) ) {
            // TODO: Change in future, fail safe check for now
            let valid = validatePaymentMethod({ payment, payment_method, payment_information: state.payment_information, connections: state.propertyConnections });
            if(!valid.status) {
              return {
                errors: valid.errors
              };
            }

            if(payment_method){
              payment_method.save_to_account = !!payment_method.save_to_account;
              payment_method.auto_charge = !!payment_method.auto_charge;
            }
          }
        }
        
        let leases = state.leases && state.leases.length && state.leases.filter(l => l.pay && l.billed_months).map(l => {
          return {
            id: l.id,
            billed_months: l.billed_months,
            amount: l.Charges && l.Charges.balance,
            auto_charge: l.pay, 
            payment_cycle: l.payment_cycle
          }
        }) || [];
        
        let selectedAutoPayLeases = getters.autoPayLeases.length && getters.autoPayLeases;
        
        let payment_info = {
          payment: payment,
          paymentMethod: payment_method || null,
          contact_id: state.contact && state.contact.id ? state.contact.id : null,
          property_id: state.property.id,
          leases: leases,
          auto_pay_leases: selectedAutoPayLeases || null,
          Invoices: state.invoices,
          use_credits: state.payment_information.use_credits,
          partial_payment: state.payment_information.partial_payment
        }

        switch(state.payment.type.toLowerCase()) {
          case 'card':
            commit("setPaymentMethod", {
              property: 'ref_name',
              propertyValue: state.payment_method.name_on_card
            });
            break;
          case 'ach':
            commit("setPaymentMethod", {
              property: 'ref_name',
              propertyValue: `${state.payment_method.first} ${state.payment_method.last}`
            });
            break;
            case 'directdebit':
            commit("setPaymentMethod", {
              property: 'ref_name',
              propertyValue: `${state.payment_method.name_on_card} `
            });
            break;
          default:
        }

        return payment_info;
      },
      resetContactEvent({commit}) {
        commit("setContact", { contact: null});
      }
    };
  }
}

export default Payments;
