import api from '../../assets/api.js';
import Vue from 'vue';
import moment from 'moment';



class ReportList {
  constructor() {
    this.namespaced = true;

    this.state = {
      report_list: [],
      active_report: {},
      views: [],
      view: {},
      searching: false,
      noTableData: false,
      report_structures: {

      },
      dynamicRunParam: {},
      selectedSpaceGroup: {},
      is_reset_grid_enable: false,
      tenantReportViews: [],
      defaultSearchFilterData: {},
      customVariance: {},
      rentChangeUploadHistory: {},
      activeDynamicReport: {},
      isRateReport: false,
      isSpaceGroupReport:false,
      bulk_contact_ids: []
    }
    this.getters = {
      getRefreshGridEnable: (state) => state.is_reset_grid_enable,
      getNoTableData: (state) => state.noTableData, 
      getCustomVariance: (state) => state.customVariance,
      geDynamicRunParam: (state) => state.dynamicRunParam,
      getDefaultSearchFilter: (state) => state.defaultSearchFilterData,
      searching: (state) => state.searching,
      views: (state) => (report_type) => {
        return state.report_structures[report_type].views;
      },
      getTenantReportViews: (state) => {
        return state.tenantReportViews ?? []
      },
      getReportColumnKeysWithoutIdFields: (state) => (report_template) => {
        return state.report_structures[report_template]?.defaultData?.columns?.reduce((arr,column) => {
          if (!column.key.endsWith('_id')) arr.push(column.key);
          return arr;
        },[]) ?? []
      },
      getReportColumnlabelKeyMapWithoutIdFields: (state) => (report_template) => {
        return state.report_structures[report_template]?.defaultData?.columns?.reduce((arr,column) => {
          if (!column.key.endsWith('_id')) arr[column.label] = column.key;
          return arr;
        },{}) ?? {}
      },
      get_auto_generated_report: (state) => (data) => {
        let auto_generated_report = state.report_structures[data.report_template].views.find(v => v.id === null);
        return auto_generated_report;
      },

      get_default_report: (state) => (data) => {
        let default_report_view = state.report_structures[data.report_template].views.find(v => v.is_default);
        if(default_report_view) return default_report_view;
      },

      get_selected_report: (state) => (data) => {
        const current_report_view_id = state.report_structures[data.report_template].current_view;

        if(current_report_view_id != '') {
          let selected_report_view = state.report_structures[data.report_template].views.find(v => v.id === current_report_view_id);
          if(selected_report_view) return selected_report_view;
        }

        return null;
      },

      get_default_view: (state, getters) => (data) => {
        if(!state.report_structures[data.report_template]) return null;

        /* - Initially on first load: current_report_view = ''
           - When user selects auto generated template report or come back to report tab: current_report_view = 'template'
           - When user selects some report other then auto generated template report: current_report_view: report_id
        */
        const current_report_view_id = state.report_structures[data.report_template].current_view;

        if(!data.use_template && current_report_view_id != 'template') {
          // If user selects some report display it until refreshed
          const selected_report = getters.get_selected_report(data);
          if(selected_report) return selected_report;

          // If user has explicitly make same report default while saving it
          const default_report = getters.get_default_report(data);
          if(default_report) return default_report;
        }

        // Template report auto generated
        const auto_generated_report = getters.get_auto_generated_report(data);
        if(auto_generated_report) return auto_generated_report;
      },

      get_report_by_id: (state) => (data) => {
        if(!data.report_id && !data.report_template) return null;

        if(!data.report_template){
          for (const [key, value] of Object.entries(state.report_structures)) {
            let view = state.report_structures[key].views.find(v => v.id === data.report_id);
            if(view) return view;
          }
        } else {
          if(!state.report_structures[data.report_template]) return null;
          return state.report_structures[data.report_template].views.find(v => v.id === data.report_id);
        }

      },

      get_selected_entries: (state) => (params) => {
        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id)
        return state.report_structures[params.report_template].views[index].selectedEntries || [];
      },


      getAllStructures:(state) => {
        return state.report_structures;
      },
      getStructure:(state) => (report_template) => {
        return state.report_structures[report_template];
      },
      get_report_index_by_id: (state) => (id) => {
        return state.report_list.findIndex(report => report.id === id)
      },
      report_list:  state => state.report_list,
      getBulkContactsList : (state)=> {
        return state.bulk_contact_ids;
      },
      getSearchParams: (state) => (params) => {
        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id);
        return state.report_structures[params.report_template].views[index].filters.search;
      },
      getPrimaryRoleId: (state, getters, rootState, rootGetters) => {
        return rootGetters['authenticationStore/getPrimaryRoleId'];
      },
      getRentChangeUploadHistory: (state) => state.rentChangeUploadHistory,
      getActiveDynamicReport:(state) => state.activeDynamicReport,
      getSelectedSpaceGroup: (state) => state.selectedSpaceGroup,
    }

    this.mutations = {
      setRestGridEnable(state, value) {
        state.is_reset_grid_enable = value;
      },
      setNoTableData(state, value) {
        state.noTableData = value;
      },
      setCustomVariance(state, payload) {
        state.customVariance = {...payload}
      },
      addReport: (state,payload) => {
        state.report_list.push(payload);
      },

      setTenantReportViews: (state, payload) => {   
        if(!state.tenantReportViews.includes(payload)) {
          state.tenantReportViews.push(payload)
        }
      },
      setBulkContactsList: (state, payload) => {
          state.bulk_contact_ids = payload
      },

      addStructure: (state,payload) => {
        Vue.set(state.report_structures, payload.report_template, payload);
      },
      setCurrentView: (state,payload) => {
        state.report_structures[payload.report_template].current_view = payload.report_id || 'template';
      },

      // setReportFilters: (state,payload) => {
      //   // let index = state.report_list.findIndex(report => report.id === payload.report_id);
      //   let index = state.report_structures[payload.report_template].views.findIndex(report => report.id === payload.report_id);
      //   state.report_structures[payload.report_template].views[index].filters = JSON.parse(JSON.stringify(payload.report_filters));
      // },

      setSort: (state,params) => {
        // let index = state.report_list.findIndex(report => report.id === params.report_id);
        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id)
        state.report_structures[params.report_template].views[index].filters.offset = params.offset;
        state.report_structures[params.report_template].views[index].filters.limit = params.limit;
        state.report_structures[params.report_template].views[index].filters.sort = params.sort || {};

      },

      setSelectedEntries: (state, params) => {
        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id)
        state.report_structures[params.report_template].views[index].selectedEntries = params.entries;
      },

      addSelectedEntry: (state, params) => {
        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id)
        state.report_structures[params.report_template].views[index].selectedEntries.push(params.entry)

      },
      removeSelectedEntry: (state, params) => {
        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id)
        state.report_structures[params.report_template].views[index].selectedEntries.splice(params.index, 1);
      },



      setSearchParams:(state,params) => {
        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id);
        Vue.set(state.report_structures[params.report_template].views[index].filters, 'search', params.search);
        Vue.set(state.report_structures[params.report_template].views[index].filters, 'offset',params.offset || 0 );
        if(Object.keys(state.dynamicRunParam).length) {
          if (params.search.report_date) {
            state.dynamicRunParam.timePeriodConfig = { ...state.dynamicRunParam.timePeriodConfig, 'report_date': params.search.report_date}
          } else if(params.search.report_period) {
            state.dynamicRunParam.timePeriodConfig = { ...state.dynamicRunParam.timePeriodConfig, 'report_period': params.search.report_period}
          }
        }
      },

      setColumns:(state,params) => {
        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id);
        // let columns = state.report_structures[params.report_template].column_structure.filter(cs => params.columns.indexOf(cs.key) >= 0 );
        let filteredColumns = []
        params.columns.forEach(colKey => {
          filteredColumns.push(state.report_structures[params.report_template].column_structure.find(cs => cs.key == colKey))
        }) // changing cause: array automatically getting sorted
        // state.report_structures[params.report_template].views[index].filters.columns = columns.length ? columns : state.report_list[index].filters.columns;
        state.report_structures[params.report_template].views[index].filters.columns = filteredColumns.length ? filteredColumns : state.report_list[index].filters.columns;
        if(params.report_template == 'unique_leads'){
          // injecting updated columns to unique leads views under active_lead reports
          // this is needed for rendering the updated columns in report as it is hard coded to active_leads template.
          let index1 = state.report_structures['active_leads'].views.findIndex(report => report.id === params.report_id);
          state.report_structures['active_leads'].views[index1].filters.columns = filteredColumns.length ? filteredColumns : state.report_list[index].filters.columns;

        }
      },
      setPivot:(state,params) => {

        //let index = state.report_list.findIndex(report => report.id === params.report_id);
        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id);
        state.report_structures[params.report_template].views[index].filters.pivot_mode = params.pivot_mode;
      },
      setDynamicRunParam:(state,params) => {
        state.dynamicRunParam = params;
      },
      setSelectedSpaceGroup: (state, payload) => {
        state.selectedSpaceGroup = payload;
      },
      setPagination:(state,params) => {
        //let index = state.report_list.findIndex(report => report.id === params.report_id);
        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id);
        state.report_structures[params.report_template].views[index].filters.page = params.page;
        state.report_structures[params.report_template].views[index].filters.offset = params.offset;
      },


      updateColumnPosition:(state,params) => {
        //let index = state.report_list.findIndex(report => report.id === params.report_id);

        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id);
        let columns = params.columns;
        state.report_structures[params.report_template].views[index].filters.columns.sort((a,b) => columns.findIndex(col => col.colDef.field === a.key) < columns.findIndex(col => col.colDef.field === b.key) ? -1 : 1);

        state.report_structures[params.report_template].views[index].filters.columns.forEach(c => {
            c.width = columns[columns.findIndex(col => col.colDef.field === c.key)]?.actualWidth;
        });

      },

      setColumnFilter:(state,params) => {
        //let index = state.report_list.findIndex(report => report.id === params.report_id);
        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id);
        let columns = state.report_structures[params.report_template].views[index].filters.columns;
        let newColumns = [];
        let column = {};

        for(let i=0; i<columns.length;i++) {
          column = JSON.parse(JSON.stringify(columns[i]));
          if(column.key === params.key){
            column.search = params.search;
          }
          newColumns.push(column);
        }
        state.report_structures[params.report_template].views[index].filters.columns = JSON.parse(JSON.stringify(newColumns));

        if(params.report_template == 'unique_leads'){
          // injecting updated columns to unique leads views under active_lead reports
          // this is needed for rendering the updated columns in report as it is hard coded to active_leads template.
          let index1 = state.report_structures['active_leads'].views.findIndex(report => report.id === params.report_id);
          state.report_structures['active_leads'].views[index1].filters.columns = JSON.parse(JSON.stringify(newColumns));

        }
      },

      setDefaultSearchFilterData:(state,params) => {
        state.defaultSearchFilterData = params;
      },

      setColumnSize:(state,params) => {
        //let index = state.report_list.findIndex(report => report.id === params.report_id);
        if(params.report_template == 'unique_leads'){
          // injecting updated columns to unique leads views under active_lead reports
          // this is needed for rendering the updated columns in report as it is hard coded to active_leads template.
          let index1 = state.report_structures['active_leads'].views.findIndex(report => report.id === params.report_id);
          state.report_structures['active_leads'].views[index1].filters.columns = state.report_structures[params.report_template].views[index].filters.columns.map(c => {
            if(c.key === params.column.colDef.field){
              c.width = params.column.actualWidth
            }
            return c;
          });

        }
        let index = state.report_structures[params.report_template].views[index].views.findIndex(report => report.id === params.report_id)
        state.report_structures[params.report_template].views[index].filters.columns = state.report_structures[params.report_template].views[index].filters.columns.map(c => {
          if(c.key === params.column.colDef.field){
            c.width = params.column.actualWidth
          }
          return c;
        });
      },


      updateFilters:(state,params) => {

        //let index = state.report_list.findIndex(report => report.id === params.report_id);
        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id)
        state.report_structures[params.report_template].views[index].filters = JSON.parse(JSON.stringify(params.data));

      },

      setFilter:(state,params) => {
        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id)
        state.report_structures[params.report_template].views[index].filters.search[params.key] = JSON.parse(JSON.stringify(params.filter));

      },

      resetReport:(state,params) => {
        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id);
        state.report_structures[params.report_template].views[index].filters = JSON.parse(JSON.stringify(state.report_structures[params.report_template].views[index].defaultData));
        state.report_structures[params.report_template].views[index].selectedEntries = [];
      },

      resetReportCache:(state) => {
        state.refreshReportCache = true
      },

      setTableData:(state,params) => {
        // let index = state.report_list.findIndex(report => report.id === params.report_id);

        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id)
        Vue.set(state.report_structures[params.report_template].views[index], 'columns',  params.columns)
        Vue.set(state.report_structures[params.report_template].views[index], 'table_data',  params.table_data)
        Vue.set(state.report_structures[params.report_template].views[index], 'result_count',  params.result_count)
        Vue.set(state.report_structures[params.report_template].views[index], 'total_count',  params.total_count)

      },

      setTableDataTotalCount: (state, params) => {
        // let index = state.report_list.findIndex(report => report.id === params.report_id);

        let index = state.report_structures[
          params.report_template
        ].views.findIndex((report) => report.id === params.report_id);

        Vue.set(
          state.report_structures[params.report_template].views[index],
          "total_count",
          params.total_count
        );
      },

      setTableDataResultCount: (state, params) => {
        // let index = state.report_list.findIndex(report => report.id === params.report_id);

        let index = state.report_structures[
          params.report_template
        ].views.findIndex((report) => report.id === params.report_id);

        Vue.set(
          state.report_structures[params.report_template].views[index],
          "result_count",
          params.result_count
        );
      },

      setReportCleanliness:(state,params) => {
        let index = state.report_list.findIndex(report => report.id === params.report_id);
        state.report_list[index].dirty = params.dirty;
      },

      setViews: (state,params) => {
        Vue.set(state.report_structures[params.template], 'views', params.views);
      },

      updateView: (state ,params) => {
        let index = state.report_structures[params.template].views.findIndex(report => report.id === params.id)

        if(index >= 0){
          Vue.set(state.report_structures[params.template].views, index, params)
        } else {
          state.report_structures[params.template].views.push(params);
        }

      },
      setStructureFilters: (state ,params) => {
        Vue.set(state.report_structures[params.report_template], 'defaultData', params.report_filters )
        Vue.set(state.report_structures[params.report_template], 'filters', params.report_filters )
      },

      setReportFilters: (state, params) => {
        let index = state.report_structures[params.report_template].views.findIndex(report => report.id === params.report_id);
        Vue.set(state.report_structures[params.report_template].views[index], 'defaultData',  params.report_filters);
        Vue.set(state.report_structures[params.report_template].views[index], 'filters',  params.report_filters);

      },

      setReportStructreEmpty: (state) => {
        state.report_structures = {};
      },

      setUpdateDeletedViews: (state, id) => {
        if(Object.keys(state.report_structures).length) {
          for (let key in state.report_structures) {
            if(Object.keys(state.report_structures[key]).includes('views')) {
              state.report_structures[key].views = state.report_structures[key].views.filter(el => {
                el.id !== id
              });
            }
          }
        }
      },

      setRentChangeUploadHistory:(state,params) => {
        state.rentChangeUploadHistory = params;
      },
      setActiveDynamicReport:(state, payload) => {
        state.activeDynamicReport = payload;
      },
      setIsRateReport:(state, payload) => {
        state.isRateReport = payload;
      },
      setIsSpaceGroupReport:(state, payload) => {
        state.isSpaceGroupReport = payload;
      },
    },

    this.actions = {
      setRestGridEnableAction({ commit }, data) {
        commit('setRestGridEnable', data)
      },
      updateCustomVariance({commit}, data){
        commit('setCustomVariance', data)
      },
      async loadReportStructure({getters, commit, dispatch}, data) {
        let found_report = {};
        let report_type =  '';
        let structure = {};
        // get the report from the store if possible

        if(data.report_id) {
          found_report = getters.get_report_by_id(data.report_id);
          if(found_report){
            report_type = found_report.report_template;
          }

        } else if(data.report_template) {
          report_type = data.report_template
        }

        if(!report_type){
          structure =  new Structure();
          await structure.getStructure(data.report_id, data.report_template, this.$app);
          structure.report_template = structure.report_type;
          commit('addStructure', structure);

        } else {

          // if there is a report type, find existing structure
          structure = await getters.getStructure(data.report_template);

          // if there is a structure but report id is null then load default
          if(structure && !data.report_id) {
            structure.id = null
          }

          // if there is no structure loaded, get it
          if(!structure){
            structure =  new Structure();
            await structure.getStructure(data.report_id, data.report_template, this.$app);
            structure.report_template = structure.report_type;
            commit('addStructure', structure);
          }
        }
        return structure;

        // if report type load that
        // if report id, find views, and get report_type - then find structure by type

      },

      async loadViews({getters, commit, dispatch}, data){

        //let report = getters.get_report_by_id(data.report_id);

        let r = await api.get(this.$app, api.REPORTS + 'saved', {
          template:  data.report_template,
          include_apps: true
        });

        let structure = getters.getStructure(data.report_template);

        let views = r.savedReports.map(v => {
          let filters = JSON.parse(v.filters);
          v.filters = filters;
          v.defaultData = JSON.parse(JSON.stringify(filters));
          return v;
        });

         // adding unique leads
         if(data.report_template == 'active_leads'){
           let structure1 =  new Structure();
           await structure1.getStructure(null, 'unique_leads', this.$app);
           structure1.report_template = 'unique_leads';
           commit('addStructure', structure1);
          //  adds view object to unique leads report structure
           structure1.views.push({
             id: 2,
             name: 'Unique Leads',
             template: 'unique_leads',
             filters: JSON.parse(JSON.stringify(getters.getStructure('unique_leads').defaultData)),
             defaultData: JSON.parse(JSON.stringify(getters.getStructure('unique_leads').defaultData)),
             selectedEntries: []
           })
          //  this is needed to show unique leads in the drop down
           views.unshift({
             id: 2,
             name: 'Unique Leads',
             template: 'unique_leads',
             filters: JSON.parse(JSON.stringify(getters.getStructure('unique_leads').defaultData)),
             defaultData: JSON.parse(JSON.stringify(getters.getStructure('unique_leads').defaultData)),
             selectedEntries: []
           });

         }   

        // ADDING THE DEFAULT RESERVATION TO THE LEADS SECTION
        
        if(data.report_template == 'active_leads'){
          let structure =  new Structure();
          await structure.getStructure(null, 'reservations', this.$app);
          structure.report_template = structure.report_type;
          commit('addStructure', structure);
          views.unshift({
            id: 1,
            name: 'Reservations',
            template: 'active_leads',
            filters: JSON.parse(JSON.stringify(getters.getStructure('reservations').defaultData)),
            defaultData: JSON.parse(JSON.stringify(getters.getStructure('reservations').defaultData)),
            selectedEntries: []
          });
        };

        views.unshift({
          id: null,
          name: structure.name,
          template: data.report_template,
          filters: JSON.parse(JSON.stringify(getters.getStructure(data.report_template).defaultData)),
          defaultData: JSON.parse(JSON.stringify(getters.getStructure(data.report_template).defaultData)),
          selectedEntries: []
        });

        commit('setViews', {
          views,
          template: data.report_template
        });

      },

      async saveView({getters, commit, dispatch}, data){
        let result = {};
        if(data.id){
          result = await api.put(this.$app, api.REPORTS + data.id, data);
          // update view in store
          data.defaultData = JSON.parse(JSON.stringify(data.filters));
          commit('updateView', data);

        } else {
          result = await api.post(this.$app, api.REPORTS, data);

          data.id = result.report.id;
          // add view to store

          data.defaultData = JSON.parse(JSON.stringify(data.filters));
          commit('updateView', data);
        }

        return data;
      },

      async getData({getters, commit, state}, payload){
        let view = JSON.parse(JSON.stringify(getters.get_report_by_id({
          report_id: payload.report_id,
          report_template: payload.report_template
        })));
        if(Object.keys(state.dynamicRunParam).length  && ((this.$app._route.query && this.$app._route.query.dynamicRun) || state.isRateReport)) {
          view.filters['properties'] = state.dynamicRunParam.propertyIDArray;
          view.filters['property_group_id'] = state.dynamicRunParam.property_group_id;
          if(state.dynamicRunParam.timePeriodConfig?.report_date) {
            view.filters['search'].report_date = state.dynamicRunParam.timePeriodConfig.report_date;
          } 
          if(state.dynamicRunParam.timePeriodConfig?.report_period) {
            view.filters['search'].report_period = state.dynamicRunParam.timePeriodConfig.report_period;
          }
          if(state.dynamicRunParam.search) {
            view.filters['search'] = {
              ...view.filters['search'],
              ...Object.keys(state.dynamicRunParam.search).length ? state.dynamicRunParam.search : {} 
            }
          }
        }
        //view.filters['limit'] = 5;
        view.filters['roles_id'] = [getters.getPrimaryRoleId];
        view.filters['current_date'] = moment().format("YYYY-MM-DD");
        view.filters['time_zone'] = moment.tz.guess();
        view.filters['report_id'] = state?.dynamicRunParam?.report_id;
        if (Object.keys(state.selectedSpaceGroup).length) {
          view.filters['unit_group_profile_id'] = state.selectedSpaceGroup?.id; 
        } 
        if(Object.keys(state.dynamicRunParam).length && state.isSpaceGroupReport) {
          view.filters['properties'] = state.dynamicRunParam.propertyIDArray;
        }
         
        let data = await api.post(this.$app, api.REPORTS + 'types/' + payload.report_template, view.filters);

        const index = state.report_structures[
          payload.report_template
        ].views.findIndex((report) => report.id === payload.report_id);


        commit('setTableData', {
          report_id: payload.report_id,
          report_template: payload.report_template,
          columns: data.columns,
          table_data: data.table_data,
          result_count: state.report_structures[payload.report_template].views[index].result_count || 0,
          total_count: state.report_structures[payload.report_template].views[index].total_count || 0,
        });
      },

      async getDataTotalCount({ getters, commit, state }, payload) {
        let view = {};
			  let skipPropertiesFromReport = true;
        if (Object.keys(state.dynamicRunParam).length  && this.$app._route.query && this.$app._route.query.dynamicRun){
          skipPropertiesFromReport = false;
        }
        
			  view.filters = {
			    roles_id: [getters.getPrimaryRoleId],
			    properties: skipPropertiesFromReport ? undefined : state.dynamicRunParam.propertyIDArray,
          property_group_id: skipPropertiesFromReport ? undefined : state.dynamicRunParam.property_group_id,
			  };
        
        if (state.dynamicRunParam.search && state.defaultSearchFilterData?.template) {
          view.filters["search"] = {
            ...view.filters?.search,
            ...(Object.keys(state.dynamicRunParam.search).length
              ? state.dynamicRunParam.search
              : {}),
          };
        }
        view.filters['time_zone'] = moment.tz.guess();
        view.filters['current_date'] = moment().format("YYYY-MM-DD");
        if(payload.report_template == 'move_in' && payload.propertyId){
          view.filters['properties'] = [`${payload.propertyId}`];
        }
        view.filters.report_id = state?.dynamicRunParam.report_id
        let data = await api.post(
          this.$app,
          api.REPORTS + "types/" + payload.report_template + "/total_count",
          view.filters
        );

        commit("setTableDataTotalCount", {
          report_id: payload.report_id,
          report_template: payload.report_template,
          total_count: data.total_count,
        });
      },

      async getDataResultCount({ getters, commit, state }, payload) {
        let view = JSON.parse(
          JSON.stringify(
            getters.get_report_by_id({
              report_id: payload.report_id,
              report_template: payload.report_template,
            })
          )
        );
        if (
          Object.keys(state.dynamicRunParam).length &&
          this.$app._route.query &&
          this.$app._route.query.dynamicRun
        ) {
          view.filters["properties"] = state.dynamicRunParam.propertyIDArray;
          view.filters["property_group_id"] = state.dynamicRunParam.property_group_id;
          if (state.dynamicRunParam.timePeriodConfig?.report_date) {
            view.filters["search"].report_date =
              state.dynamicRunParam.timePeriodConfig.report_date;
          }
          if (state.dynamicRunParam.timePeriodConfig?.report_period) {
            view.filters["search"].report_period =
              state.dynamicRunParam.timePeriodConfig.report_period;
          }
          if (state.dynamicRunParam.search) {
            view.filters["search"] = {
              ...view.filters["search"],
              ...(Object.keys(state.dynamicRunParam.search).length
                ? state.dynamicRunParam.search
                : {}),
            };
          }
        }

        view.filters["roles_id"] = [getters.getPrimaryRoleId];
        view.filters['current_date'] = moment().format("YYYY-MM-DD");
        view.filters['time_zone'] = moment.tz.guess();
        view.filters["report_id"] = state?.dynamicRunParam?.report_id;
        let data = await api.post(
          this.$app,
          api.REPORTS + "types/" + payload.report_template + "/result_count",
          view.filters
        );

        commit("setTableDataResultCount", {
          report_id: payload.report_id,
          report_template: payload.report_template,
          result_count: data.result_count,
        });
      },

      async clearReportStructure({getters, commit, dispatch}){
        commit('setReportStructreEmpty');
      },

      updateDeletedViews({commit}, id) {
        commit('setUpdateDeletedViews', id);
      },
      updateActiveDynamicReport({commit}, payload) {
        commit('setActiveDynamicReport', payload);
      },
      addBulkContacts({ commit }, contactIds) {
        commit('setBulkContactsList', contactIds);
      }
    }
  }
}







class Structure {
  constructor() {
    this.id = '';
    this.name = '';

    this.description = '';
    this.report_type = '';
    this.filter_structure = [];
    this.column_structure = [];
    this.filters = {
      sort: {
        field: "",
        dir: ""
      }
    };
    this.template = '';
    this.columns = [];
    this.limit = 0;
    this.dirty = true;
    this.offset = 0;
    this.page = 1;
    this.current_view = '';
    this.pivot_mode = {
      type: '',
      column: {},
      row: {},
      pivot_field: {},
      agg_method: {}
    };
    this.table_data = [];
    this.search = {};
    this.sort = {
      field: "",
      dir: ""
    };
    this.result_count = '';
    this.defaultData = [];
    this.selectedEntries = [];
    this.views = [];
    this.total_count = '';
    this.isTotalCount = false;
  }


  async getStructure(report_id,report_template, context ){

    //let id = report_id === report_template? null : report_id
    let data = {
      template: report_template
    }
    if(report_id){
      data.id = report_id;
    }
    let response = await api.get(context, api.REPORTS + 'structure', data);

    this.id = report_id;
    // this.template = response.config.template;
    this.report_type = response.config.template;
    this.name = response.config.name;
    this.description = response.description ;
    this.filter_structure = response.config.filter_structure ;
    this.filters = response.config.filters;
    this.column_structure = response.config.column_structure ;
    this.defaultData = JSON.parse(JSON.stringify(response.config.filters));
    this.isTotalCount = response.config.total_count || false;
    this.selectedEntries = [];

    // this.view.name = this.report_name;

  }
  async getData(){
    let data = JSON.parse(JSON.stringify(this.filters));
    return await api.post(this.$app, api.REPORTS + 'types/' +  this.report_type, data);
  }
}

export default ReportList;
